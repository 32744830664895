<template>
  <div>

    <template v-if="$permissionAbility(ATTENDANCE_SEE_ALL_REPORT, permissions)">
      <b-card>
        <!-- search input -->
        <validation-observer ref="generateAllAttendanceValidation">
          <b-form v-on:submit.prevent="generateAllAttendanceReport">
            <div>
              <b-row>
                <b-col md="4" lg="3" xs="12">
                  <b-form-group label="" label-for="empId">
                    <ValidationProvider
                      name="User"
                      v-slot="{ errors }"
                      vid="empId"
                    >
                      <v-select
                        id="empId"
                        name="empId"
                        v-model="selectEmpId"
                        :options="filterEmpIdOption"
                        :reduce="(item) => item.id"
                        v-on:input="loadAttendance('user')"
                        label="name"
                        placeholder="Select Employee"
                        class="mb-1 custom-font"
                      >
                        <template #option="data">
                          <UserSelect :user="data" />
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col
                    md="2"
                    lg="2"
                    xs="12"
                >
                  <v-select
                      v-model="filterStatusId"
                      :options="statusIdOption"
                      :reduce="(item) => item.value"
                      label="name"
                      placeholder="Select Status"
                      class="mb-1 custom-font"
                      v-on:input="loadAttendance()"
                  />
                </b-col>

                <b-col md="4" lg="4" xs="12">
                  <v-select
                    v-model="selectDepartmentId"
                    :options="filterDepartmentIdOption"
                    :reduce="(item) => item.id"
                    v-on:input="loadAttendance('department')"
                    label="name"
                    placeholder="Select Department"
                    class="mb-1 custom-font"
                  />
                </b-col>


                <b-col md="4" lg="3  " xs="12">
                  <flat-pickr
                      v-model="filterRangeDate"
                      v-on:input="loadAttendance('date')"
                      class="form-control"
                      placeholder="Select Date Range"
                      :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
                  />
                </b-col>

              </b-row>
            </div>

            <div
              class="custom-search d-flex align-items-center justify-content-end mt-1"
            >
              <div
                class="d-flex flex-column flex-sm-row align-items-center justify-content-around"
              >
                <template v-if="isAttendanceReportGenerating">
                  <b-button
                    class="flex-shrink-0 ml-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    disabled
                  >
                    <b-spinner small />
                    Report Generating ..
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    class="flex-shrink-0 ml-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="
                      filterStartDate && filterEndDate
                        ? false
                        : true
                    "
                  >
                    Generate PDF Report
                  </b-button>
                </template>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </template>

    <template v-else>
      <template
          v-if="$permissionAbility(ATTENDANCE_SEE_SELF_REPORT, permissions)"
      >
        <b-card title="Personal Report">
          <div>
            <div>
              <b-row>
                <b-col md="5" lg="5" xs="12">

                  <flat-pickr
                      v-model="filterSelfRangeDate"
                      class="form-control"
                      placeholder="Filter Date Range"
                      :date-disabled-fn="personalReportDateDisabled"
                      :config="{ mode: 'range', defaultDate: [this.filterSelfStartDate, this.filterSelfEndDate]}"
                  />
                </b-col>
                <b-col md="2" lg="2" xs="12">
                  <template v-if="isAttendanceSelfReportGenerating">
                    <b-button
                        class="flex-shrink-0 ml-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        disabled
                    >
                      <b-spinner small />
                      Report Generating ..
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                        class="flex-shrink-0 ml-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        v-on:click="generateSelfAttendanceReport"
                        :disabled="
                      filterSelfRangeDate ? false : true
                    "
                    >
                      Generate PDF Report
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </template>
    </template>

  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  VBModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from 'vue-flatpickr-component'
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  ATTENDANCE_SHOW,
  ATTENDANCE_SEARCH,
  ATTENDANCE_SEE_ALL_REPORT,
  ATTENDANCE_SEE_SELF_REPORT,
} from "@/helpers/permissionsConstant";
import { minutesToHours } from "@/helpers/helpers";
import { EventBus } from "@/helpers/event-bus";
import strSnakeToPascal from "@/filter/strSnakeToPascal";
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: "AttendanceView",
  components: {
    UserSelect,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    flatPickr,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // filter
      statusIdOption: [
        {
          name: 'Active',
          value: 'active',
        },
        {
          name: 'Inactive',
          value: 'inactive',
        },
      ],
      filterEmpTypeIdOption: [],
      filterStatusId: 'active',
      selectEmpTypeId: "",
      filterDivisionIdOption: [],
      selectDivisionId: "",
      filterRangeDate: null,
      filterDepartmentIdOption: [],
      selectDepartmentId: "",
      filterEmpIdOption: [],
      selectEmpId: "",
      filterStartDate: "",
      filterEndDate: "",
      filterSelfRangeDate: "",
      filterSelfStartDate: "",
      filterSelfEndDate: "",
      isAttendanceReportGenerating: false,
      isAttendanceSelfReportGenerating: false,
      ATTENDANCE_SHOW,
      ATTENDANCE_SEARCH,
      ATTENDANCE_SEE_ALL_REPORT,
      ATTENDANCE_SEE_SELF_REPORT,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
  },

  async created() {
    // EventBus.$on("changed-check-in-out", () => {
    //   if (this.$route?.name === "admin-attendance") {
    //     this.loadItems();
    //   }
    // });

    try {
      const currentDate = new Date();

      const todaysDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      this.filterStartDate = todaysDate;
      this.filterEndDate = todaysDate;
      this.filterSelfStartDate = todaysDate;
      this.filterSelfEndDate = todaysDate;

      const [employeeTypes, divisions, departments, employees] =
        await Promise.all([
          this.getEmployeeTypes(),
          this.getDivisions(),
          this.getDepartments(),
          this.getActiveEmployees(),
        ]);

      // employee type
      this.filterEmpTypeIdOption = (employeeTypes?.data?.data || []).map(
        (item) => {
          let name = item?.name;
          return {
            name,
            id: item?.id,
          };
        }
      );

      // department
      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
        (item) => {
          let name = item?.name;

          return {
            name,
            divisionId: item?.division_id,
            id: item?.id,
          };
        }
      );

      // division
      this.filterDivisionIdOption = (divisions?.data?.data || []).map(
        (item) => {
          let name = item?.name;
          return {
            name,
            id: item?.id,
          };
        }
      );

      // employee
      this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    handleSelfDateRangeChange()
    {
      if (this.filterSelfRangeDate && this.filterSelfRangeDate !== '') {
        const dates = this.filterSelfRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterSelfStartDate = dates[0];
          this.filterSelfEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterSelfStartDate = dates[0];
          this.filterSelfEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    personalReportDateDisabled(date) {
      const currentDate = new Date();

      const todaysDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      return date > todaysDate;
    },

    async loadAttendance()
    {
      const employees = await this.getAllEmployees();
      this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
        return {
          status: item?.status,
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      })

      if(this.selectEmpId)
      {
        this.filterStatusId = ''
        this.selectDepartmentId = ''
      }

      if (this.selectDepartmentId) {
        this.filterEmpIdOption = this.filterEmpIdOption.filter(
            (item) => item?.departmentId == this.selectDepartmentId
        )
      }

      if (this.filterStatusId) {
        const status = this.filterStatusId === 'active'
        this.filterEmpIdOption = this.filterEmpIdOption.filter(
            (item) => item?.status === status
        )
      }
    },

    async getActiveEmployees() {
      return await this.$api.get("api/users/active-all");
    },
    async getAllEmployees() {
      return await this.$api.get("api/users/all");
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getEmployeeTypes() {
      return await this.$api.get("api/employee-types/all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },

    saveFile(file) {
      const blob = new Blob([file], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      //open report pdf in new tab
      link.target = "_blank"; // Open in a new tab
      link.click();


      // link.download = "attendance-report.pdf";
      // link.click();
    },

    async generateAllAttendanceReport() {
      this.$refs.generateAllAttendanceValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              this.isAttendanceReportGenerating = true;

              this.handleDateRangeChange()

              const response = await this.$api.get(
                "api/attendances/report/self-stream",
                {
                  responseType: "blob",
                  params: {
                    startDate: this.filterStartDate,
                    endDate: this.filterEndDate,
                    deptId: this.selectDepartmentId,
                    empId: this.selectEmpId,
                    status: this.filterStatusId,
                  },
                }
              );

              await this.saveFile(response.data);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Attendance Report Generated Successfully",
                },
              });

              this.isAttendanceReportGenerating = false;
            } catch (error) {
              this.isAttendanceReportGenerating = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.generateAllAttendanceValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },

    async generateSelfAttendanceReport() {
      try {
        this.isAttendanceSelfReportGenerating = true;

        this.handleSelfDateRangeChange()

        const response = await this.$api.get(
          "api/attendances/report/self-stream",
          {
            responseType: "blob",
            params: {
              startDate: this.filterSelfStartDate,
              endDate: this.filterSelfEndDate,
              empId: this.authUser?.id
            },
          }
        );

        await this.saveFile(response.data);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Attendance Report Generated Successfully",
          },
        });

        this.isAttendanceSelfReportGenerating = false;
      } catch (error) {
        this.isAttendanceSelfReportGenerating = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
//@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
//@import '@core/scss/vue/libs/vue-select.scss';
//.table-custom-style {
//  font-size: 14px !important;
//  white-space: nowrap !important;
//  min-height: 140px !important;
//  tr,
//  th,
//  td {
//    vertical-align: middle !important;
//    text-align: center !important;
//  }
//}
//.custom-font {
//  font-size: 13px !important;
//}
</style>
